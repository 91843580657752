:root {
    --primary: #fff;
    --font-color: #000;
    --font-color-input: #868686;
    --font-color-in-card: #000;
    --font-color-in-modal: #2D3250;
    --background-color: #fff;
    --background-color-cards: #fff;
    --link-color-in-card: #000;
    --background-color-button: #3D4351;
    --gradient-color-start: #D7D7FD;
    --gradient-color-middle: #fff;
    --gradient-color-start-timetracking: #D7D7FD;
        --gradient-color-middle-timetracking: #fdeaf4;
        --gradient-color-end-timetracking: #FAEAF8;
    --gradient-color-end: #FAEAF8;
    --button-in-modal-bg-color: #000;
    --modal-blur-bg-color: rgb(148 151 159 / 70%);
    --background-color-theme-changer: #2D3250;
    --new-error-color: #FE9496;
    --new-success-color: #4ac923;
    --font-color-white: #000;
    --gradient-color-main-buttons: #000;
    --gradient-color-main-buttons-sticky: #000;
    --sidebar-item-hover-background-color: #e7e7e7;
    --sidebar-headline-color: #656565;

}

/* 2 */
[data-theme="dark"] {
    --font-color: #b0b2d5;
    --font-color-input: #8A8CB2;
    --font-color-in-card: #c7c8e0;
    --font-color-in-modal: #fff;
    --background-color: #2D3250;
    --background-color-header: #7077A1;
    --background-color-cards: #7077A1;
    --link-color-in-card: #fff;
    --background-color-button: #7077A1;
    --gradient-color-start: #3232b7;
    --gradient-color-middle: #421176;
    --gradient-color-start-timetracking: #656565;
        --gradient-color-middle-timetracking: #656565;
        --gradient-color-end-timetracking: #656565;
    --gradient-color-end: #6f2f7c;
    --button-in-modal-bg-color: #8A8CB2;
    --modal-blur-bg-color: rgb(78 79 82 / 70%);
    --background-color-theme-changer: #fff;
    --new-error-color: #a35657;
    --new-success-color: #38981b;
    --font-color-white: #fff;
    --gradient-color-main-buttons: #421176;
    --gradient-color-main-buttons-sticky: #8A8CB2;
    --sidebar-item-hover-background-color: #8A8CB2;
    --sidebar-headline-color: #363b67;
}

[data-theme="thinkport"] {
    --gradient-color-start: #01BBD3;
    --gradient-color-middle: #99ecf7;
    --gradient-color-end: #01BBD3;
    --gradient-color-start-timetracking: #99ecf7;
    --gradient-color-middle-timetracking: #99ecf7;
    --gradient-color-end-timetracking: #99ecf7;
    --button-in-modal-bg-color: #8A8CB2;
    --modal-blur-bg-color: rgb(78 79 82 / 70%);
    --primary: #fff;
    --font-color: #000;
    --font-color-input: #868686;
    --font-color-in-card: #000;
    --font-color-in-modal: #2D3250;
    --background-color: #fff;
    --background-color-cards: #fff;
    --link-color-in-card: #000;
    --background-color-button: #3D4351;
    --background-color-theme-changer: #2D3250;
    --new-error-color: #FE9496;
    --new-success-color: #4ac923;
    --font-color-white: #000;
    --gradient-color-main-buttons: #000;
    --gradient-color-main-buttons-sticky: #000;
    --sidebar-item-hover-background-color: #e7e7e7;
    --sidebar-headline-color: #656565;
}

[data-theme="thinkport"] .timetrack-record-bg-gradient {
    background: linear-gradient(200deg, var(--gradient-color-start), var(--gradient-color-middle));
}

[data-theme="light"] .timetrack-record-bg-gradient {
    background: linear-gradient(200deg, var(--gradient-color-start), var(--gradient-color-end));
}

body {
    background-color: var(--background-color);
    color: var(--font-color);
}

.nav-items-text-headline {
    color: var(--font-color-white);
}

.absence-bg-color {
    color: white;
    background: linear-gradient(to right, #f88967, #FE5723);
}

[data-theme="light"] .absence-bg-color {
    background: linear-gradient(to right, #E52E71, #9B36B7);
    color: white;
}

.gradient-text {
    background: linear-gradient(to right, #FF5721, #ff6531, #FF5721);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
    
        /* Fallback für Browser, die webkit-text-fill-color nicht unterstützen */
        display: inline;
    }
    
    [data-theme="light"] .gradient-text {
    background: linear-gradient(to right, #FF8A00, #E52E71, #9B36B7);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;

    /* Fallback für Browser, die webkit-text-fill-color nicht unterstützen */
    display: inline;
}


.modal-blur-background {
    background-color: var(--modal-blur-bg-color);
}

.card-bg-color {
    background-color: var(--background-color-cards);
}

.background-color-sticky-header {
    background-color: var(--background-color-cards);
}

.header-bg-color {
    background-color: var(--background-color-header);
}

.button-bg-color {
    background-color: var(--gradient-color-main-buttons);
}

.button-bg-color-sticky {
    background-color: var(--gradient-color-main-buttons-sticky);
}


.modal-bg-color {
    background-color: var(--background-color-cards);
}

.modal-color {
    color: var(--font-color-in-modal);
}

.in-card-text {
    color: var(--font-color-in-card);
}

.button-in-modal-bg-color {
    background-color: var(--button-in-modal-bg-color);
}

.no-border-color {
    border-color: transparent;
}

.capacity-button-bg-color {
    background-color: var(--background-color);
}

.capacity-button-hover-button-text-color {
    background-color: var(--font-color-in-modal);
}

.text-input {
    color: var(--font-color-input);
}

.card-border-lg {
    border-radius: 50px;
    padding: 20px;
    box-shadow:
        0 10px 20px rgb(87 19 248 / 10%),
        /* Weicher Schatten */
        0 0 1px rgb(87 19 248 / 10%);
    transition: box-shadow 0.3s ease-in-out;
    /* stylelint-disable-next-line no-missing-end-of-source-newline */
}

.card-border-sm {
    border-radius: 30px;
    padding: 18px;
    box-shadow:
        0 8px 16px rgb(87 19 248 / 10%),
        /* Weicher Schatten */
        0 0 3px rgb(87 19 248 / 10%);
    transition: box-shadow 0.3s ease-in-out;
    /* stylelint-disable-next-line no-missing-end-of-source-newline */
}

.theme-button-text-color-worktime {
    color: #D1DBFF;
}

[data-theme="thinkport"] .theme-button-text-color-worktime {
    color: #000;
}

[data-theme="thinkport"] .card-border-sm {
    border-radius: 30px;
    padding: 18px;
    box-shadow:
        0 8px 16px rgb(1 187 211 / 10%),
        /* Weicher Schatten */
        0 0 3px rgb(1 187 211 / 10%);
    transition: box-shadow 0.1s ease-in-out;
    /* stylelint-disable-next-line no-missing-end-of-source-newline */
}

.sticky-header-border {
    border-radius: 30px;
    padding: 20px;
}

.sticky-header-shadow {
    box-shadow: 0 0 10px rgb(87 19 248 / 30%); /* Stärkerer Schatten mit höherer Opazität */
    transition: box-shadow 0.3s ease-in-out;
}


.new-border-radius {
    border-radius: 50px;
}


.sticky-soft-shadow {
    box-shadow:
            40px 10px 20px rgb(87 19 248 / 10%), /* Weicher Schatten nach unten */
            0 0 1px rgb(87 19 248 / 10%); /* Leichter Schatten um das Element */

    transition: box-shadow 0.2s ease-in-out;
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1; /* Höher als der Inhalt, der verschwinden soll */
    background-color: var(--background-color-cards)
}

.sticky-header::before {
    content: "";
    display: block;
    height: 20px; /* Höhe des oberen Abstands */
    width: 100%;
    position: absolute;
    top: -20px; /* Negativer Abstand um den oberen Bereich zu überdecken */
    left: 0;
    background-color: var(--background-color);
    z-index: -1; /* Unter dem Header */
}

.sidebar-item-hover-background-color:hover {
    background-color: var(--sidebar-item-hover-background-color)
}


.new-shadow {
    box-shadow:
        0 10px 20px rgb(87 19 248 / 10%),
        /* Weicher Schatten */
        0 0 1px rgb(87 19 248 / 10%);
    transition: box-shadow 0.2s ease-in-out;
    /* stylelint-disable-next-line no-missing-end-of-source-newline */
}

[data-theme="thinkport"] .new-shadow {
    box-shadow:
        0 10px 20px #01BBD3,
        /* Weicher Schatten */
        0 0 1px #01BBD3;
    transition: box-shadow 0.2s ease-in-out;
    /* stylelint-disable-next-line no-missing-end-of-source-newline */
}

.sidebar-headline {
    color: var(--sidebar-headline-color);
}

.new-inner-shadow {
    box-shadow:
        inset 0 2px 4px rgb(87 19 248 / 10%),
        /* Innerer Schatten */
        inset 0 0 1px rgb(87 19 248 / 10%);

    /* Feiner innerer Rand */
    transition: box-shadow 0.2s ease-in-out;
}

.btn-circle {
    border-radius: 60px;
    text-align: center;
}

.btn-theme-changer {
    background-color: var(--background-color-theme-changer);
}

.gradient-button {
    background: linear-gradient(130deg, var(--gradient-color-start), var(--gradient-color-middle), var(--gradient-color-end));
    padding: 10px 20px;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.timetrack-record {
    background: linear-gradient(130deg, var(--gradient-color-start-timetracking), var(--gradient-color-middle-timetracking));
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

[data-theme="thinkport"] .timetrack-record {
    background: var(--gradient-color-middle-timetracking);
}


.gradient-header-button {
    display: flex;
    align-items: center;

    /* Vertikale Zentrierung */
    justify-content: center;

    /* Horizontale Zentrierung */
    text-align: center;
    color: var(--font-color);

    /* Zusätzliche Sicherstellung der horizontalen Zentrierung des Textes */
    background: linear-gradient(130deg, var(--gradient-color-start), var(--gradient-color-middle), var(--gradient-color-end));
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.gradient-primary {
    background: linear-gradient(200deg, #D7D7FD, #fff, #FAEAF8);
}

.gradient-secondary {
    background: linear-gradient(200deg, #D7D7FD, #FAEAF8);
}

.gradient-small-white {
    background: radial-gradient(circle at 30% 50%, rgb(215 215 253 / 50%) 20%, transparent 80%),
            radial-gradient(circle at 70% 50%, rgb(250 234 248 / 50%) 30%, transparent 80%),
        #fff;
}

.text-new-success {
    color: #1BCFB4;
}

.bg-new-success {
    background: #4ac923;
}

.text-new-warning {
    color: #4BCBEB;
}

.bg-new-warning {
    background: #4BCBEB;
}

.text-new-error {
    color: #FE9496;
}

.bg-new-error {
    background: #FE9496;
}




/* Grundstil für die Sprechblase */
.speech-bubble {
    display: inline-block;
    position: relative;
    border-radius: 10px;
    padding: 1rem;
}

/* Spezifische Stile für die Sprechblase von rechts */
.speech-bubble-right {
    background: #D7D7FD;

    /* Hellblau für rechts */
}

.speech-bubble-right::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: #D7D7FD;

    /* Pfeilfarbe für die rechte Sprechblase */
    transform: translateY(-50%);
}

/* Spezifische Stile für die Sprechblase von links */
.speech-bubble-left {
    background: #FAEAF8;

    /* Hellrosa für links */
}

.speech-bubble-left::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #FAEAF8;

    /* Pfeilfarbe für die linke Sprechblase */
    transform: translateY(-50%);
}
@keyframes pulse {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }
}

.animate-pulse {
    animation: pulse 2s ease-in-out infinite;
}

.kennzeichen {
    font-family: 'DIN 1451', Arial, sans-serif;
    background-color: #f8f9fa;
    border: 1px solid #333;
    color: #1a1a1a;
    font-size: 12px;

    /* 50% kleiner */
    text-align: center;
    padding: 6px 12px;

    /* 50% kleiner */
    display: inline-block;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
    margin: 4px;

    /* 50% kleiner */
    width: 110px;

    /* 50% kleiner */
    border-radius: 1.5px;

    /* 50% kleiner */
    letter-spacing: 1px;

    /* 50% kleiner */
    position: relative;
}

.kennzeichen::before {
    content: 'D';
    display: block;
    background-color: #039;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 20px;

    /* 50% kleiner */
    border-top-left-radius: 1.5px;
    border-bottom-left-radius: 1.5px;
    text-align: center;
    padding-top: 3px;

    /* 50% kleiner */
    font-size: 10px;

    /* 50% kleiner */
}

.kennzeichen>div {
    padding-left: 20px;
}

.language-dropdown {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5em;
}

.language-dropdown select {
    width: 100%; /* Make select fill the container width */
    padding: 0.5em;
    margin-left: 1em; /* Space between icon and select */
    border-radius: 15px; /* Rounded corners */
    background-color: var(--background-color); /* Removes default style on WebKit browsers */ /* Removes default style on Mozilla browsers */
    appearance: none; /* Standard syntax to remove default arrow */
    box-shadow:
            0 10px 20px rgb(87 19 248 / 10%),
                /* Weicher Schatten */
            0 0 1px rgb(87 19 248 / 10%);
    transition: box-shadow 0.3s ease-in-out;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 4 5"><path fill="%23007bff" d="M2 0L0 2h4L2 0zm0 5L0 3h4l-2 2z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 12px auto, 100%;
}

.language-dropdown select:focus {
    outline: none;
    border-color: #007bff; /* Highlight color when focused */
}

/* Adjust the FontAwesome icon size if needed */
.language-dropdown svg {
    width: 1em;
    height: 1em;
}

@keyframes skeleton-loading {
    0% {
        background-color: #eee;
    }

    50% {
        background-color: #ddd;
    }

    100% {
        background-color: #eee;
    }
}
@keyframes pop-and-intense-shake {
    0% {
        transform: scale(0.5);
    }

    20% {
        transform: scale(1.2);
    }

    40% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.1) rotate(-10deg);
    }

    60% {
        transform: scale(1.1) rotate(10deg);
    }

    70% {
        transform: scale(1.1) rotate(-10deg);
    }

    80% {
        transform: scale(1.1) rotate(10deg);
    }

    90% {
        transform: scale(1.1) rotate(-5deg);
    }

    100% {
        transform: scale(1) rotate(0deg);
    }
}

.pop-shake {
    animation: pop-and-intense-shake 2s ease-out forwards;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.heart-icon {
    font-size: 4vw;
}

@media (min-width: 600px) {
    .heart-icon {
        font-size: 3vw;
    }
}

@media (min-width: 768px) {
    .heart-icon {
        font-size: 2vw;
    }
}

@media (min-width: 992px) {
    .heart-icon {
        font-size: 1.5vw;
    }
}

.card-figure {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 25px;
}

.card-figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.card-body {
    min-height: 150px;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
