/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    max-width: 55%;
    cursor: pointer;
}

.modal-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    cursor: pointer;
    color: white;
    z-index: 1001;
}
