

.sidebar-icon {
    margin-right: 20px; /* Gleichmäßiger Abstand zwischen Icon und Text */
    width: 20px; /* Optionale feste Breite für Icons */
    text-align: center; /* Zentriert das Icon */
}

.sidebar {
    width: 250px;
    position: fixed;
    justify-content: space-between;
    top: 20px;
        left: 20px;
        bottom: 20px;
        box-shadow: 0 0 15px rgb(0 0 0 / 20%);
    padding-top: 1rem;
    border-radius: 30px;
    display: block;
    }
    
    @media (max-width: 668px) {
        .sidebar {
            display: block;

            /* Sichtbar ab 'md' Größe */
            width: 150px;
            
            /* Kleinere Breite für 'sidebar-small' Stil */
        }
 }

.sidebar-divider {
    display: block; /* Stellt sicher, dass span sich wie ein Block-Element verhält */
    height: 1px; /* Die Höhe des Dividers */
    background-color: #CCC; /* Die Farbe des Dividers */
    width: 100%; /* Stellt sicher, dass der Divider die gesamte Breite einnimmt */
}

.sidebar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4px; /* Fügt einen Abstand von 4px auf allen Seiten hinzu */
    margin: 4px 0; /* Optional: Fügt oben und unten einen Abstand von 4px hinzu */
    text-decoration: none;
}

.sidebar-logo img {
    max-width: calc(100% - 20px); /* Reduziert die maximale Breite des Bildes, um den Abstand von 4px auf jeder Seite zu berücksichtigen */
    height: auto;
}



.sidebar-logo:hover {
    transform: scale(1.05); /* Vergrößert das Logo leicht beim Hovern */
}



.sidebar-item {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 24px; /* Erhöht das horizontale Padding auf 24px */
    margin-top: 10px; /* Vertikaler Abstand zwischen den Items */
    text-decoration: none; /* Entfernt Unterstreichung von Links */
    border-radius: 15px; /* Optional, für abgerundete Ecken */
    width: 100%; /* Sidebar-Items nutzen die volle Breite */
}

.sidebar-headline-item
{
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px; /* Erhöht das horizontale Padding auf 24px */
    margin-top: 10px; /* Vertikaler Abstand zwischen den Items */
    margin-bottom: -15px; /* Vertikaler Abstand zwischen den Items */
    text-decoration: none; /* Entfernt Unterstreichung von Links */
    width: 100%; /* Sidebar-Items nutzen die volle Breite */
}

.sidebar-icon svg {
    fill: currentcolor; /* Verwendet die Textfarbe für das Icon */
    width: 24px; /* Festlegen einer Standardgröße für Icons */
    height: 24px; /* Festlegen einer Standardgröße für Icons */
}


.sidebar-icon-and-text {
    display: flex;
    align-items: center; /* Zentriert die Inhalte vertikal */
    justify-content: center; /* Zentriert die Inhalte horizontal */
}


.sidebar-bottom {
    position: absolute;
    bottom: 0; /* Setzt .sidebar-bottom am unteren Rand des .sidebar Containers */
    width: calc(100% - 30px); /* Gleiche Berechnung wie für .sidebar-item */
}
